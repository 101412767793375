@use '@strategies/tokens/layout';

.Process {
    background: $color_brand--secondary !important;
    .image-wrap {
        img {
            padding-top: layout.$padding--large * 2;
            max-width: 100%;
        }
    }

    h2,
    h3,
    p {
        color: $color_bg-light-green !important;
    }

}